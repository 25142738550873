exports.components = {
  "component---src-components-templates-product-brand-tsx": () => import("./../../../src/components/templates/productBrand.tsx" /* webpackChunkName: "component---src-components-templates-product-brand-tsx" */),
  "component---src-components-templates-product-category-tsx": () => import("./../../../src/components/templates/productCategory.tsx" /* webpackChunkName: "component---src-components-templates-product-category-tsx" */),
  "component---src-components-templates-single-product-tsx": () => import("./../../../src/components/templates/singleProduct.tsx" /* webpackChunkName: "component---src-components-templates-single-product-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-check-email-tsx": () => import("./../../../src/pages/check-email.tsx" /* webpackChunkName: "component---src-pages-check-email-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-account-tsx": () => import("./../../../src/pages/my-account.tsx" /* webpackChunkName: "component---src-pages-my-account-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-registration-tsx": () => import("./../../../src/pages/registration.tsx" /* webpackChunkName: "component---src-pages-registration-tsx" */),
  "component---src-pages-reset-password-thank-you-tsx": () => import("./../../../src/pages/reset-password-thank-you.tsx" /* webpackChunkName: "component---src-pages-reset-password-thank-you-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-set-password-tsx": () => import("./../../../src/pages/set-password.tsx" /* webpackChunkName: "component---src-pages-set-password-tsx" */),
  "component---src-pages-shipping-and-refund-policy-tsx": () => import("./../../../src/pages/shipping-and-refund-policy.tsx" /* webpackChunkName: "component---src-pages-shipping-and-refund-policy-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-ty-for-contacting-us-tsx": () => import("./../../../src/pages/ty-for-contacting-us.tsx" /* webpackChunkName: "component---src-pages-ty-for-contacting-us-tsx" */),
  "component---src-pages-ty-for-registration-tsx": () => import("./../../../src/pages/ty-for-registration.tsx" /* webpackChunkName: "component---src-pages-ty-for-registration-tsx" */)
}

